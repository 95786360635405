import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { Suspense, useEffect, useMemo, useState, useTransition } from "react";
import { ThreadForm, ThreadList } from "../components/comments/comments";
import {
  nearestRainfallStation,
  selectedBoreholesWaterlevelsSelector,
  selectedDataPointFamily,
  waterLevelsData,
  waterLevelsDataPointThreads,
  waterLevelsDataType,
  waterLevelsDataTypeSelector,
  waterLevelsGraphReversed,
  waterLevelsGuides,
  waterLevelsNoBaseDrys,
  waterLevelsMax,
  waterLevelsMin,
  waterLevelsPipesState,
  waterLevelsSeries,
  waterLevelsUnitsSelector
} from "../state/recoil";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable
} from "recoil";

import { Alert } from "../components/alert/alert";
import { ErrorBoundary } from "../components/errorBoundary/errorBoundary";
import EsriNavigateButton from "../components/esri-navigate/esri-navigate";
import { Feature } from "flagged";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { PointDetails } from "../components/pointDetails/pointDetails";
import SeriesGraph from "../components/SeriesGraph";
import api from "../lib/api";
import config from "../lib/config";
import fileDownload from "js-file-download";
import logUserActivity from "../common/user-activity-logging";
import { stringify } from "qs";
import { useAuth } from "../components/auth-provider/auth-provider";
import { useSearchParams } from "react-router-dom";

const BoreholeSelect = ({ className }) => {
  const boreholes = useRecoilValueLoadable(waterLevelsPipesState);
  const [selectedBoreholes, setSelectedBoreholes] = useRecoilState(selectedBoreholesWaterlevelsSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const preselectedPipes = searchParams.get('pipes')?.split(',');
    const preselectedBoreholes = searchParams.get('boreholes')?.split(',');
    if (preselectedPipes || preselectedBoreholes) {
      const filteredPreselectedBoreholes = boreholes.getValue().filter(borehole => (preselectedPipes?.includes(borehole.borehole_pipe_id) || preselectedBoreholes?.includes(borehole.locationdata_borehole_id)));
      if (filteredPreselectedBoreholes?.length > 0) {
        setSelectedBoreholes(filteredPreselectedBoreholes);
      }
    }
  }, []);

  const handleBoreholeChange = async (event, value) => {
    if (value !== null) {
      setSelectedBoreholes(value);
    }
  };

  return (
    <Tooltip
      title={"Pipe screen data will not be displayed for multiple boreholes"}
    >
      <Autocomplete
        className={className}
        sx={{ userSelect: "unset" }}
        multiple={true}
        getOptionLabel={(option) => (option ? option.borehole_pipe_id : "")}
        disablePortal
        id="combo-box-demo"
        value={selectedBoreholes}
        options={boreholes.getValue()}
        renderInput={(params) => (
          <TextField {...params} label="Borehole pipe" />
        )}
        onChange={handleBoreholeChange}
      />
    </Tooltip>
  );
};

const Filters = () => {
  const [dataType, setDataType] = useRecoilState(waterLevelsDataTypeSelector);
  const [units, setUnits] = useRecoilState(waterLevelsUnitsSelector);
  const [graphReversed, setGraphReversed] = useRecoilState(waterLevelsGraphReversed);

  const handleUnitsChange = (event, value) => {
    if (value !== null) {
      setGraphReversed(event.target.value !== "maod");
      setUnits(event.target.value);
    }
  };

  const handleDateTypeChange = (event, value) => {
    setDataType(value.props.value);
  };

  const handleGraphReverse = async (event, value) => {
    if (event.target.value !== null) setGraphReversed(event.target.value);
  };

  return (
    <Card>
      <CardContent>
        <Box>
          <FormGroup row>
            <FormControl sx={{ m: 1 }}>
              <InputLabel id={"dataType"}>Graph type</InputLabel>

              <Select
                label={"Graph type"}
                id={"dataType"}
                value={dataType}
                onChange={handleDateTypeChange}
              >
                <MenuItem value={"dailyAverage"}>Daily Average</MenuItem>
                <MenuItem value={"all"}>All readings</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: 150 }} sx={{ m: 1 }}>
              <InputLabel id={"units"}>Units</InputLabel>
              <Select
                label={"Reverse Y axis"}
                id={"units"}
                value={units}
                onChange={handleUnitsChange}
              >
                <MenuItem value={"mbgl"}>mBGL</MenuItem>
                <MenuItem value={"maod"}>mAOD</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: 150 }} sx={{ m: 1 }}>
              <InputLabel id={"reversed"}>Reverse Y axis</InputLabel>
              <Select
                disabled={units === "maod"}
                label={"Reverse Y axis"}
                id={"reversed"}
                value={graphReversed}
                onChange={handleGraphReverse}
              >
                <MenuItem value={true}>Yes</MenuItem>
                <MenuItem value={false}>No</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
  );
};

const RainfallStationData = () => {
  const nearestStation = useRecoilValue(nearestRainfallStation);

  if (nearestStation) {
    return (
      <Card>
        <CardContent>
          <Typography variant="h5">EA Rain Gauge Details</Typography>
          <Typography>
            <strong>EA ID:</strong> {nearestStation.stationReference}
            <br />
            <strong>Lat:</strong> {nearestStation.lat}
            <br />
            <strong>Long:</strong> {nearestStation.long}
            <br />
            <strong>
              Distance to selected water level monitoring point:
            </strong>{" "}
            {(nearestStation.distance / 1000).toFixed(3)} km
            <br />
          </Typography>
        </CardContent>
      </Card>
    );
  } else {
    return <></>;
  }
};

const Graph = () => {
  const guides = useRecoilValue(waterLevelsGuides);
  const data = useRecoilValue(waterLevelsSeries);
  const wlData = useRecoilValue(waterLevelsData);
  const min = useRecoilValue(waterLevelsMin);
  const max = useRecoilValue(waterLevelsMax);
  const reversed = useRecoilValue(waterLevelsGraphReversed);
  const units = useRecoilValue(waterLevelsUnitsSelector);
  const type = useRecoilValue(waterLevelsDataType);

  const getColor = (name) => {
    if (name === "ground") {
      return config.colors.groundLevel;
    }
    if (name === "screenTop" || name === "screenBot") {
      return config.colors.pipe;
    }
    return "gray";
  };

  const guidesArray = Object.keys(guides || {})
    .filter((key) => ["ground", "screenTop", "screenBot"].includes(key))
    .map((key) => {
      return {
        value: parseFloat(guides[key]),
        color: getColor(key),
        name: key,
      };
    });

  // Get array of points with a dry reading to display, IF we have screen base
  const pointsWithDryReadings = useMemo(() => {
    let dataPoints = [];

    data.forEach((series) => {
      if (wlData[series.index]?.guides?.screenBot) {
        series.data.forEach((point) => {
          if (point.isDry) {
            dataPoints = [
              ...dataPoints,
              {
                series: series.name,
                id: point.ags_data_id,
                x: point.x
              },
            ];
          }
        });
      }
    });

    return dataPoints;
  }, [data]);

  const pointsWithComments = useMemo(() => {
    let dataPoints = [];
    data.forEach((dataSet) => {
      dataSet.data.forEach((point) => {
        if (point.comment_count) {
          dataPoints = [
            ...dataPoints,
            {
              series: dataSet.name,
              id: point.ags_data_id,
              x: point.x,
              commentCount: point.comment_count,
              openCommentCount: point.open_comment_count,
            },
          ];
        }
      });
    });

    return dataPoints;
  }, [data]);

  const makeAnnotations = useMemo(() => {
    const annotations = [];

    if (guides?.screenTop || guides?.screenTop === 0) {
      annotations.push({
        start: guides.screenTop,
        end: guides.screenTop + 0.02,
        color: config.colors.pipe,
        name: "Pipe screen top",
      });
    }

    if (guides?.screenBot || guides?.screenBot === 0) {
      annotations.push({
        start: guides.screenBot,
        end: guides.screenBot + 0.02,
        color: config.colors.pipe,
        name: "Pipe screen bottom",
      });
    }

    if (guides?.ground || guides?.ground === 0) {
      annotations.push({
        start: guides.ground - 0.0025,
        end: guides.ground + 0.0025,
        color: config.colors.groundLevel,
        name: "Ground level",
      });
    }
    return annotations;
  }, [guides]);

  const DownloadButton = () => {
    const { user } = useAuth();
    const pipes = useRecoilValue(selectedBoreholesWaterlevelsSelector);
    const [isDownloading, setIsDownloading] = useState(false);
    const handleButtonClicked = async () => {
      setIsDownloading(true);
      const response = await api.get('/waterlevel/download/csv', {
        responseType: 'blob',
        params: {
          pipeIDs: pipes.map(pipe => pipe.borehole_pipe_id)
        },
        paramsSerializer: params => {
          return stringify(params)
        }
      })
      const filename = response.headers['content-disposition'].split('"')[1] + '.csv';
      fileDownload(response.data, filename);
      setIsDownloading(false);
    };

    return (
      user?.allow_downloads && <Tooltip title="Download data as CSV file">
        <IconButton disabled={isDownloading}>
          {isDownloading ?
            <CircularProgress color="inherit" size={16} />
            :
            <FileDownloadIcon onClick={handleButtonClicked} />}
        </IconButton>
      </Tooltip>);
  };

  if (data && data.length && data.find(series => series.data && series.data.length)) {
    return (
      <SeriesGraph
        dataType={"waterlevels"}
        data={data}
        guides={guidesArray}
        isReversed={reversed}
        min={min}
        max={max}
        yTitle={`Groundwater Level (${units})`}
        canSelectPoint={type === "all"}
        pointsWithDryReadings={pointsWithDryReadings}
        pointsWithComments={pointsWithComments}
        downloadButton={DownloadButton}
      ></SeriesGraph>
    );
  } else {
    return <></>;
  }
};

const CommentsBlock = ({ dataType }) => {
  const [selectedDataPoint, setSelectedDataPoint] = useRecoilState(
    selectedDataPointFamily(dataType)
  );
  const [start, trn] = useTransition();
  const threads = useRecoilValue(waterLevelsDataPointThreads);
  const type = useRecoilValue(waterLevelsDataType);
  const refreshThreads = useRecoilRefresher_UNSTABLE(
    waterLevelsDataPointThreads
  );
  const refreshData = useRecoilRefresher_UNSTABLE(waterLevelsData);

  return (
    <>
      {type === "all" && selectedDataPoint && (
        <>
          <Box marginBottom={3}>
            <ThreadForm
              selectedDataPoint={selectedDataPoint}
              afterSubmit={(thread) => {
                trn(() => {
                  setSelectedDataPoint({
                    ...selectedDataPoint,
                    threads: selectedDataPoint.threads ? [...selectedDataPoint.threads, thread] : [thread],
                  });
                  refreshData();
                  refreshThreads();
                });
              }}
            />
          </Box>
          <Box>
            <ThreadList threads={threads} dataType={dataType} />
          </Box>
        </>
      )}
    </>
  );
};

const Alerts = () => {
  const data = useRecoilValue(waterLevelsSeries);
  const boreholes = useRecoilValueLoadable(waterLevelsPipesState).getValue() || [];

  // Check for 'dry' readings
  const hasDryReadings = data.some(series => series.data.some(point => point.isDry));
  const noBaseDryReadings = useRecoilValue(waterLevelsNoBaseDrys);

  const checkSeriesForData = series => series.name.toUpperCase().includes('RAINFALL') || series.data.some(point => !isNaN(point.y));
  const locationsWithoutUnitData = data.filter(series => !checkSeriesForData(series)).map(series => series.name);

  const [searchParams] = useSearchParams();
  const preselectedBoreholes = searchParams.get('boreholes')?.split(',') || [];
  const preselectedPipes = searchParams.get('pipes')?.split(',') || [];

  const preselectedBoreholesWithoutData = preselectedBoreholes.filter(preselectedBorehole => !boreholes.find(borehole => preselectedBorehole === borehole.locationdata_borehole_id));
  const preselectedPipesWithoutData = preselectedPipes.filter(preselectedPipe => !boreholes.find(borehole => preselectedPipe === borehole.borehole_pipe_id));

  const hasAlerts = locationsWithoutUnitData.length > 0 || preselectedBoreholesWithoutData.length > 0 || preselectedPipesWithoutData.length > 0 || hasDryReadings;

  const handleAGOLClick = () => {
    logUserActivity({
      action: 'CLICKED',
      component: 'ALERT_AGOL_LINK_WATERLEVEL',
      data: config.ESRI_WEB_APP_WATER_LEVEL_LAYER_URL_CONCAT + preselectedBoreholesWithoutData.join(','),
    });
  };

  return hasAlerts && (
    <Box marginBottom={3} marginTop={3}>
      <Card>
        <CardContent>
          {locationsWithoutUnitData.length > 0 && <Alert title="Warning - no information for selected unit" >
            No groundwater level information is available for <strong>{locationsWithoutUnitData.join(', ')}</strong> in the selected unit, consider switching units.
          </Alert>}
          {preselectedBoreholesWithoutData.length > 0 && <Alert title={`No information for available monitoring ${preselectedBoreholesWithoutData.length > 1 ? 'points' : 'point'}`}>
            No graphable groundwater level data is available for <strong>{preselectedBoreholesWithoutData.join(', ')}</strong>. <br />
            It may be that no monitoring data is available for this point, or that 'dry' readings exist that cannot be plotted, or data may be available under a different alias (please see the dropdown menu above).
          </Alert>}
          {preselectedPipesWithoutData.length > 0 && <Alert title={`No information for available monitoring ${preselectedPipesWithoutData.length > 1 ? 'installations' : 'installation'}`}>
            No graphable groundwater level data is available for <strong>{preselectedPipesWithoutData.join(', ')}</strong>. <br />
            It may be that no monitoring data is available for this point, or that 'dry' readings exist that cannot be plotted, or data may be available under a different alias (please see the dropdown menu above).
          </Alert>}
          {hasDryReadings && <Alert severity="warning" title="Dry readings detected">
            ‘Dry’ readings detected within the raw data. Some, or all, of the ‘dry’ readings cannot be accurately plotted on the below graph; please refer to raw data for more details (download button below), or hydrograph excel export(s) accessed via the geospatial GWR App (link above).
            {noBaseDryReadings !== null && <>
              <p><strong>
                One or more of the selected borehole pipes does not have pipe screen base information,
                so we are unable to plot those dry readings. These readings are:
              </strong></p>
              <ul>
                {Object.keys(noBaseDryReadings).map(pipeName =>
                  <li key={`nbdr_pn_${pipeName}`}>
                    <strong>{pipeName}</strong>
                    <ul>
                      {noBaseDryReadings[pipeName].map(i =>
                        <li key={`nbdr_r_${i.id}`}>
                          <strong>ID: </strong> {`${i.id}, `}
                          <strong>Date/time: {new Date(i.dateTime).toLocaleString()}</strong>
                        </li>
                      )}
                    </ul>
                  </li>
                )}
              </ul>
            </>}
          </Alert>}
          {(preselectedBoreholesWithoutData.length > 0 || preselectedPipesWithoutData.length > 0) && <Alert severity="info" title="More information may be available">
            Please visit the following <strong>EKFB Groundwater Risk App (GWRA)</strong> link for detailed georeferenced excel based hydrograph attachments: <br />
            <strong><a onClick={handleAGOLClick} target="_blank" href={`${config.ESRI_WEB_APP_WATER_LEVEL_LAYER_URL_CONCAT}(${preselectedBoreholes?.join(',')})`}>{config.ESRI_WEB_APP_WATER_LEVEL_LAYER_NAME}</a></strong>.
          </Alert>}
        </CardContent>
      </Card>
    </Box>
  );
};

const WaterLevels = () => {
  const [selectedBoreholes, setSelectedBoreholes] = useRecoilState(selectedBoreholesWaterlevelsSelector);

  return (
    <Box paddingBottom={3}>
      <Box marginBottom={3}>
        <Card>
          <CardContent>
            <Suspense fallback="Loading boreholes...">
              <div className="flex">
                <BoreholeSelect className="w-full mr-4" />
                <EsriNavigateButton boreholes={selectedBoreholes?.map(borehole => borehole.locationdata_borehole_id)} url={config.ESRI_WEB_APP_WATER_LEVEL_LAYER_URL_CONCAT} componentName="ESRI_NAVIGATE_BUTTON_WATERLEVEL" />
              </div>
            </Suspense>
          </CardContent>
        </Card>
      </Box>
      <Box>
        <Box marginBottom={3}>
          <ErrorBoundary>
            <Suspense>
              <Filters></Filters>
            </Suspense>
          </ErrorBoundary>
        </Box>
        <Box marginBottom={3}>
          <ErrorBoundary>
            <Suspense>
              <Alerts></Alerts>
            </Suspense>
          </ErrorBoundary>
        </Box>
        <Feature name="rainfall">
          <Box marginBottom={3}>
            <ErrorBoundary>
              <Suspense fallback="Loading station info...">
                <RainfallStationData />
              </Suspense>
            </ErrorBoundary>
          </Box>
        </Feature>
        <Box marginBottom={3}>
          <ErrorBoundary>
            <Suspense fallback="Loading water levels...">
              <Graph />
            </Suspense>
          </ErrorBoundary>
        </Box>
        <Box marginBottom={3}>
          <ErrorBoundary>
            <Suspense>
              <PointDetails dataType={"waterlevels"} />
            </Suspense>
            <Suspense fallback={"Loading comments..."}>
              <CommentsBlock dataType={"waterlevels"} />
            </Suspense>
          </ErrorBoundary>
        </Box>
      </Box>
    </Box>
  );
};

export default WaterLevels;

