import { Alert, AlertTitle, Button, CircularProgress, Grid, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import featureFlags from "../feature-flag.json";
import api from "../lib/api";
import PasswordForm from "./admin/user/password-form";
import { Link } from 'react-router-dom';

export default function UnlockPage() {
    const { id } = useParams();
    const [isReady, setIsReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isExpired, setIsExpired] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const unlock = async () => {
            try {
                const lock = (await api.get(`/user/lock/${id}`)).data;
                if (lock && lock.id) {
                    const user = (await api.post(`/user/unlock/${id}`)).data;
                    if (user && user.id) {
                        setIsSuccess(true);
                    } else {
                        setIsError(true);
                    }
                } else {
                    setIsExpired(true);
                }
            } catch (err) {
                if (featureFlags.sentry) {
                    Sentry.captureException(err);
                }
                setIsExpired(true);
            }
        }
        unlock().catch(console.log);
    }, [id])

    return (
        <Grid justifyContent="left" container>
            <Grid item xs={12}>
                <Typography variant="h2">Unlock Account</Typography>
            </Grid>
            <Grid className="pt-12" item xs={12} md={4}>
                {isSuccess &&
                    <Alert severity="success">
                        <AlertTitle>Account unlocked</AlertTitle>
                        Your account has been unlocked successfully.
                        <br />
                        You can now <Link to="/login"><strong>Login</strong></Link>.
                    </Alert>
                }
                {isError &&
                    <Alert severity="error">
                        <AlertTitle>Account not unlocked</AlertTitle>
                        Account unlock failed.
                    </Alert>
                }
                {isExpired &&
                    <Alert severity="error">
                        <AlertTitle>Link expired</AlertTitle>
                        The link has expired after certain amount of time.
                    </Alert>
                }
            </Grid>
        </Grid>
    );
}
