import './alert.css'

import {
    AlertTitle,
    Alert as MuiAlert
} from "@mui/material";

export const Alert = ({title, children, severity='warning'}) => (
    <MuiAlert severity={severity}>
      {title ? <AlertTitle><strong>{title}</strong></AlertTitle> : null}
      {children}
    </MuiAlert>
  );