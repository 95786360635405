import { useAuth } from "../components/auth-provider/auth-provider";
import { LoginForm } from "../components/loginForm";
import { Navigate } from "react-router-dom";

export default function Login() {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    return <Navigate to="/dashboard"></Navigate>;
  }
  return <LoginForm></LoginForm>;
}
