/**
 * Hook to redirect from http to https...
 * 
 * Could probably be a script in the root index.html file, but for speed,
 * ease, and not thinking about NOSCRIPTS etc, handling it within the existing
 * React app.
 *  - Tom D
 */

import { useEffect } from 'react';

export const useHTTPRedirect = () => {
  // Is this localhost? Allow it.
  const isLocalHost = hostname => !!(
    hostname === 'localhost' ||
    hostname === '[::1]' ||
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      window.location &&
      window.location.protocol === 'http:' &&
      !isLocalHost(window.location.hostname)
    ) {
      window.location.href = window.location.href.replace(
        /^http(?!s)/,
        'https'
      );
    }
  }, []);
};
