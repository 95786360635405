import { Card, CardContent, Stack, Typography } from "@mui/material";
import { selectedDataPointFamily, waterLevelsLocationData } from "../../state/recoil";

import { useRecoilValue } from "recoil";

export const ShowDataOrNotAvailable = (data) => {
  if(!data && data !== 0) return  'N/A';
  return data;
}
 
export const PointDetails = ({ dataType }) => {
  const point = useRecoilValue(selectedDataPointFamily(dataType));
  const location = useRecoilValue(waterLevelsLocationData);
  const pointLocation = location.find(item => item.locationdata_borehole_id === point?.boreholeId);

  return (
    <>
      {point && (
        <Card sx={{mb: 3}}>
          <CardContent>
            <Typography gutterBottom variant="h5">
              Selected data point
            </Typography>
            <Stack direction={"column"}>
              <Typography>
                <b>ID:</b> {ShowDataOrNotAvailable(point.id)}
              </Typography>
              <Typography>
                <b>Borehole ID: </b>
                { ShowDataOrNotAvailable(point.boreholeId)}
              </Typography>
              <Typography>
                <b>National grid easting: </b>
                {ShowDataOrNotAvailable(pointLocation?.national_grid_easting)}
              </Typography>
              <Typography>
                <b>National grid northing: </b>
                {ShowDataOrNotAvailable(pointLocation?.national_grid_northing)}
              </Typography>
              <Typography>
                <b>Borehole Pipe ID: </b>
                {ShowDataOrNotAvailable(point.boreholePipeId)}
              </Typography>
              <Typography>
                <b>Date: </b>
                {ShowDataOrNotAvailable([point.date.toLocaleDateString(), point.date.toLocaleTimeString()].join(' '))}
              </Typography>
              <Typography>
                <b>Value: </b>
                {ShowDataOrNotAvailable(point.value)}
              </Typography>
            </Stack>
            <Typography></Typography>
          </CardContent>
        </Card>
      )}
    </>
  );
};
