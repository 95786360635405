import { AutocompleteElement, FormContainer, PasswordElement, PasswordRepeatElement, TextFieldElement } from 'react-hook-form-mui'
import { constSelector, useRecoilValue } from 'recoil';
import { userRoles, userRolesOptions } from "../../../lib/user-roles";

import { TextField } from '@mui/material';
import { useUserRoles } from '../../../hooks/use-user-role';
import { userGroupsData } from '../../../state/recoil';

const UserForm = ({ defaultValues, isEditMode, onSubmit, isEmailDisabled = false, isRoleDisabled = false, isPasswordDisabled = false, isPasswordHidden = false, isGroupsHidden = false }) => {
  const isPlatformAdmin = useUserRoles([userRoles.platform_admin]);
  const isAdmin = useUserRoles([userRoles.admin]);
  const roles = isPlatformAdmin ? userRolesOptions : userRolesOptions.slice(0, -1);
  const userGroups = useRecoilValue((isAdmin || isPlatformAdmin) ? userGroupsData: constSelector([]));

  return (
    <FormContainer
      defaultValues={defaultValues}
      onSuccess={onSubmit}
      FormProps={{
        id: "user-form"
      }}
    >
      <TextFieldElement
        label={'First name'}
        name={'first_name'}
        required
        fullWidth
        margin={'dense'}
      />
      <TextFieldElement
        label={'Last name'}
        name={'last_name'}
        required
        fullWidth
        margin={'dense'}
      />
      <TextFieldElement
        type={'email'}
        label={'Email'}
        name={'email'}
        required
        disabled={isEditMode || isEmailDisabled ? true : false}
        fullWidth
        margin={'dense'}
      />
      <AutocompleteElement
        name="role"
        label={'Role'}
        options={roles}
        required
        autocompleteProps={{
          disabled: isRoleDisabled
        }}
        textFieldProps={{
          margin: "dense",
        }}
      />
      {!isGroupsHidden && (isAdmin || isPlatformAdmin) &&
              <AutocompleteElement
              name="groups"
              label={'Groups'}
                  sx={{ userSelect: "unset" }}
                  multiple
                  getOptionLabel={option => option.name}
                  disablePortal
                  id="combo-box-demo"
                  options={userGroups.map(group => ({id: group.id, label: group.name}))}
                  renderInput={(params) => (
                      <TextField {...params} name="name" label="Name" />
                  )}
              />
      }
      {
        !isPasswordHidden &&
        <div>
          <PasswordElement
            label={'Password'}
            name={'password'}
            required={isEditMode ? false : true}
            disabled={isPasswordDisabled ? true : false}
            fullWidth
            margin={'dense'}
            validation={{minLength: {value: 8, message: 'Password must contain at least 8 characters'}}}
            inputProps={{
              autoComplete: 'off'
            }}
          />
          <PasswordRepeatElement
            passwordFieldName={'password'}
            name={'password-repeat'}
            label={'Repeat Password'}
            disabled={isPasswordDisabled ? true : false}
            fullWidth
            margin={'dense'}
          /></div>
      }

    </FormContainer>
  );
};

export default UserForm;
