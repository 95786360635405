import './user.css';

import {
  Alert,
  Button,
  Card,
  CardContent,
  CircularProgress,
  DialogContent,
  IconButton,
  InputAdornment,
  Snackbar,
  Switch,
  TextField
} from "@mui/material";
import { Suspense, useRef, useState } from "react";
import {
  compareItems,
  rankItem
} from '@tanstack/match-sorter-utils';
import { createColumnHelper, flexRender, getCoreRowModel, getFilteredRowModel, getSortedRowModel, sortingFns, useReactTable } from "@tanstack/react-table";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";

import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import EditIcon from '@mui/icons-material/Edit';
import InviteUserForm from "./invite-user-form";
import LockOpen from '@mui/icons-material/LockOpen';
import SearchIcon from '@mui/icons-material/Search';
import UserForm from "./user-form";
import api from "../../../lib/api";
import { confirmAlert } from "react-confirm-alert";
import { useUserRoles } from '../../../hooks/use-user-role';
import { userRoles } from '../../../lib/user-roles';
import { usersData } from "../../../state/recoil";

const UserAdminPage = () => {

  const [isUserDialogOpen, setIsUserDialogOpen] = useState(false);
  const [isInviteDialogOpen, setIsInviteDialogOpen] = useState(false);

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [defaultValues, setDefaultValues] = useState();
  const [selectedUser, setSelectedUser] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const isPlatformAdmin = useUserRoles([userRoles.platform_admin]);

  const refreshUsersData = useRecoilRefresher_UNSTABLE(usersData);

  const Table = () => {
    const gridRef = useRef();
    const data = useRecoilValue(usersData);

    const [filter, setFilter] = useState('');
    const [sorting, setSorting] = useState([]);


    const fuzzyFilter = (row, columnId, value, addMeta) => {
      // Rank the item
      const itemRank = rankItem(row.getValue(columnId), value)

      // Store the itemRank info
      addMeta({
        itemRank,
      })

      // Return if the item should be filtered in/out
      return itemRank.passed
    }

    const fuzzySort = (rowA, rowB, columnId) => {
      let dir = 0

      // Only sort by rank if the column has ranking information
      if (rowA.columnFiltersMeta[columnId]) {
        dir = compareItems(
          rowA.columnFiltersMeta[columnId].itemRank,
          rowB.columnFiltersMeta[columnId].itemRank
        )
      }

      // Provide an alphanumeric fallback for when the item ranks are equal
      return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir
    }

    const columnHelper = createColumnHelper();

    const columns = [
      columnHelper.accessor('edit', {
        header: '',
        maxSize: 40,
        cell: props => (
          <div>
            <IconButton aria-label="edit" onClick={() => { handleEditUserClicked(props.row.original) }}>
              <EditIcon />
            </IconButton>
          </div>
        )
      }),
      columnHelper.accessor('email', {
        header: 'Email',
        filterFn: 'fuzzy',
      }),
      columnHelper.accessor('first_name', {
        header: 'First name',
        filterFn: 'fuzzy',
      }),
      columnHelper.accessor('last_name', {
        header: 'Last name',
        filterFn: 'fuzzy',
      }),
      columnHelper.accessor('role', {
        header: 'Role'
      }),
      columnHelper.accessor('created_timestamp', {
        header: 'Created at',
        cell: props => props.getValue().split(' ')[0],
      }),
      columnHelper.accessor('is_locked', {
        header: '',
        maxSize: 40,
        cell: props => (
          <IconButton disabled={!props.getValue()} aria-label="toggle-lock" onClick={() => { handleUnlockButtonClicked(props.row.original) }}>
            <LockOpen />
          </IconButton>
        )
      }),
      columnHelper.accessor('is_enabled', {
        header: 'Enabled',
        maxSize: 60,
        cell: props => (
          <Switch
            checked={props.getValue()}
            onChange={e => { handleToggleUserActiveChanged(e.target.checked, props.row.original) }}
            inputProps={{ 'aria-label': 'toggle-enabled' }}
          />
        )
      }),
    ];

    // isPlatformAdmin && columns.push(
    //   columnHelper.accessor('delete', {
    //     header: '',
    //     maxSize: 40,
    //     cell: props => (
    //       <IconButton aria-label="delete" onClick={() => { handleDeleteUserClicked(props.row.original) }}>
    //         <DeleteIcon />
    //       </IconButton>
    //     )
    //   }));

    const handleToggleUserActiveChanged = async (value, user) => {
      await api.put(`/user/${user.id}`, { is_enabled: value ? true : false });
      setSnackbarMessage(value ? 'User enabled' : 'User disabled');
      setIsSnackbarOpen(true);
      refreshUsersData();
    }

    const table = useReactTable({
      data,
      columns,
      enableColumnResizing: true,
      columnResizeMode: 'onChange',
      getCoreRowModel: getCoreRowModel(),
      // debugTable: true,
      // debugHeaders: true,
      // debugColumns: true,
      filterFns: {
        fuzzy: fuzzyFilter,
      },
      onGlobalFilterChange: setFilter,
      globalFilterFn: fuzzyFilter,
      state: {
        globalFilter: filter,
        sorting
      },
      getFilteredRowModel: getFilteredRowModel(),
      onSortingChange: setSorting,
      getSortedRowModel: getSortedRowModel(),
    })

    const handleEditUserClicked = (user) => {
      setIsLoading(false);
      setIsEditMode(true);
      setSelectedUser({ ...user, password: '', 'password-repeat': '' });
      setIsUserDialogOpen(true);
    }

    const handleUnlockButtonClicked = async (user) => {
      await api.post(`/user/admin/unlock/${user.id}`);
      refreshUsersData();
      setSnackbarMessage('User unlocked');
      setIsSnackbarOpen(true);
    }

    const handleDeleteUserClicked = async (user) => {
      const onCancel = (onClose) => {
        onClose();
      }

      const onConfirm = async (onClose) => {
        await api.delete(`/user/${user.id}`);
        refreshUsersData();
        onClose();
        setSnackbarMessage('User deleted');
        setIsSnackbarOpen(true);
      }

      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to do this.",
        customUI: ({ onClose }) => {
          return (
            <Dialog
              open={true}
              onClose={() => {
                onCancel(onClose);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Are you sure want to delete this user?
              </DialogTitle>
              <DialogContent>
                {/* <DialogContentText
                  component="div"
                  id="alert-dialog-description"
                >Are you sure want to delete this user?
                </DialogContentText> */}
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    onCancel(onClose);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    onConfirm(onClose);
                  }}
                  autoFocus
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          );
        },
      });
    }

    const handleUserFormSubmitted = async (data, id) => {
      setIsLoading(true);

      const user = {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        role: data.role.value,
        password: data.password,
        groups: data.groups?.map(group => group.id)
      };

      if (isEditMode) {
        await api.put(`/user/${selectedUser.id}`, user);
      }
      else {
        await api.post("/user/create", user);
      }

      refreshUsersData();
      setIsUserDialogOpen(false);
      setSnackbarMessage(isEditMode ? 'User information saved' : 'New user created');
      setIsSnackbarOpen(true);
      setIsLoading(false);
    }

    const handleInviteClicked = async () => {
      setIsLoading(false);
      setIsInviteDialogOpen(true);
    }

    const handleAddButtonClicked = () => {
      setIsLoading(false);
      setIsEditMode(false);
      setSelectedUser(null);
      setIsUserDialogOpen(true);
    }

    const handleInviteUserFormSubmitted = async (value) => {
      setIsLoading(true);
      try {
        await api.post('/user/invite', { ...value, role: value.role.value, groups: value.groups?.map(group => group.id) });
      } catch {
        // setIsError(true);
      }
      setIsInviteDialogOpen(false);
      setSnackbarMessage('User invitation sent');
      setIsSnackbarOpen(true);
      setIsLoading(false);
    }

    return (
      <div>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={isSnackbarOpen}
          onClose={() => { setIsSnackbarOpen(false) }}
          autoHideDuration={3000}
        >
          <Alert onClose={() => { setIsSnackbarOpen(false) }} severity="success">
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Dialog
          // className={styles.disclaimer}
          open={isUserDialogOpen}
          onClose={() => { setIsUserDialogOpen(false) }}
          maxWidth='sm'
          scroll={"paper"}
          fullWidth={true}
        >
          <DialogTitle>{isEditMode ? 'Edit user' : 'Create new user'}</DialogTitle>
          <DialogContent>
            <UserForm defaultValues={selectedUser}
              isEditMode={isEditMode}
              isPasswordHidden={!isPlatformAdmin}
              onSubmit={handleUserFormSubmitted} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => { setIsUserDialogOpen(false) }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              form="user-form"
              autoFocus
              variant="contained"
              disabled={isLoading}
            >
              {isEditMode ? 'Save' : 'Create'} {isLoading && <CircularProgress color="inherit" size={16} sx={{ ml: 1 }} />}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={isInviteDialogOpen}
          onClose={() => { setIsInviteDialogOpen(false) }}
          fullWidth={true}
          maxWidth='sm'
          scroll={"paper"}
        >
          <DialogTitle>Invite new user</DialogTitle>
          <DialogContent><InviteUserForm onSubmit={handleInviteUserFormSubmitted}></InviteUserForm></DialogContent>
          <DialogActions>
            <Button
              onClick={() => { setIsInviteDialogOpen(false) }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              form="invite-user-form"
              autoFocus
              variant="contained"
              disabled={isLoading}
            >
              Invite {isLoading && <CircularProgress color="inherit" size={16} sx={{ ml: 1 }} />}
            </Button>
          </DialogActions>
        </Dialog>

        <div className="columns-2 flex">
          <div className="w-full flex justify-start">
            <div>
              <TextField id="filter-input"
                className="filter-input"
                name={"filter"}
                onChange={e => { setFilter(e.target.value) }}
                label="Search"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }} />
            </div>
          </div>
          <div className="w-full flex justify-end">
            <div>
              <Button onClick={handleInviteClicked} variant="outlined">
                Invite
              </Button>
            </div>
            <div className="ml-4">
              {isPlatformAdmin &&
                <Button onClick={handleAddButtonClicked} variant="outlined">
                  Add
                </Button>
              }
            </div>
          </div>
        </div>

        <table className="w-full mt-8">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ position: 'relative', width: header.getSize() }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      {header.column.getCanResize() && (
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''
                            }`}
                        ></div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map(row => {
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id} style={{ width: cell.column.getSize() }}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
          {/* <tfoot>
          {table.getFooterGroups().map(footerGroup => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map(header => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot> */}
        </table>
      </div>

    );
  };

  return (
    <Card >
      <CardContent>
        <Suspense>
          <div className="p-4">
            <Table />
          </div>
        </Suspense>
      </CardContent>
    </Card>
  );
};

export default UserAdminPage;
