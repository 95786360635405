export const userRoles = {
    user: 'user',
    admin: 'admin',
    platform_admin: 'platform_admin'
};

export const userRolesOptions = [
    {
        label: 'User',
        value: userRoles.user
    },
    {
        label: 'Admin',
        value: userRoles.admin
    },
    {
        label: 'Platform admin',
        value: userRoles.platform_admin
    }
];