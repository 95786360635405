import { AutocompleteElement, FormContainer, PasswordElement, PasswordRepeatElement, TextFieldElement } from 'react-hook-form-mui'
import { userRoles } from "../../../lib/user-roles";

const PasswordForm = ({ defaultValues, onSubmit }) => {

  return (
    <FormContainer
      defaultValues={defaultValues}
      onSuccess={onSubmit}
      FormProps={{
        id: "password-form"
      }}
    >
      <PasswordElement
        label={'Password'}
        name={'password'}
        autoFocus
        required
        fullWidth
        margin={'dense'}
        validation={{minLength: {value: 8, message: 'Password must contain at least 8 characters'}}}
        inputProps={{
          autoComplete: 'off'
        }}
      />
      <PasswordRepeatElement
        passwordFieldName={'password'}
        name={'password-repeat'}
        label={'Repeat Password'}
        fullWidth
        margin={'dense'}
      />
    </FormContainer>
  );
};

export default PasswordForm;
