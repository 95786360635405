import React, { useRef } from "react";
import { agreedToTermsAtom, appInfoState, disclaimerLoadingAtom, disclaimerOpenAtom } from "../../state/recoil";
import { constSelector, useRecoilState, useRecoilValue } from "recoil";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from './EKFB/dialog-content';
import DialogTitle from "@mui/material/DialogTitle";
import api from "../../lib/api";
import styles from "./disclaimer.module.css";
import { useAuth } from "../auth-provider/auth-provider";
import { useEffect } from "react";

export default function Disclaimer() {
  const AGREEMENT_VERSION = '0.1';
  const [isOpen, setOpen] = useRecoilState(disclaimerOpenAtom);
  const [isAgreed, setAgreed] = useRecoilState(agreedToTermsAtom);
  const { isAuthenticated, loading } = useAuth();
  const { user } = useAuth();

  const appInfo = useRecoilValue(isAuthenticated ? appInfoState : constSelector({}));

  useEffect(() => {
    if (!isOpen && !isAgreed && isAuthenticated && !loading) {
      setOpen(true);
    }
  }, [isAgreed, loading, appInfo, isAuthenticated, isOpen]);

  const descriptionElementRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        const { current: descriptionElement } = descriptionElementRef;
        if (descriptionElement !== null) {
          descriptionElement.focus();
        }
      });
    }
  }, [isOpen]);

  const handleClose = async (event, reason) => {
    if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
      if (user?.has_agreed !== AGREEMENT_VERSION) {
        try {
          await agree();
          setAgreed(true);
          sessionStorage.setItem("agreedToTerms", "true");
        } catch (error) {
          console.error("Failed to agree to terms:", error);
          setOpen(false);
        }
      } else {
        setAgreed(true);
        sessionStorage.setItem("agreedToTerms", "true");
      }
    }
    setOpen(false);
  };

  const agree = async () => {
    try {
      const { data: agreement } = await api.post("agreement", {
        component: 'POPUP_DISCLAIMER',
        version: AGREEMENT_VERSION,
        has_agreed: true
      });
      return agreement;
    } catch (error) {
      throw new Error('Failed to record agreement');
    }
  }

  return (
    <Dialog
      className={styles.disclaimer}
      open={isOpen}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Terms and Conditions</DialogTitle>
      <DialogContent open={isOpen} appInfo={appInfo} agreed={isAgreed}></DialogContent>
      <DialogActions>
        <Button onClick={handleClose} ref={descriptionElementRef}>
          <strong>{isAgreed ? 'Close' : 'Agree'}</strong>
        </Button>
      </DialogActions>
    </Dialog>
  );
}