import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { useEffect, useRef } from "react";

import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Feature } from 'flagged';
import Typography from "@mui/material/Typography";
import config from "../../../lib/config";
import logo from "./logo.png";
import styles from "../disclaimer.module.css";
import { useAuth } from "../../auth-provider/auth-provider";

export default function HS2Disclaimer({ open, appInfo, agreed }) {
  const { user } = useAuth();

  const formatDate = (date) => {
    if (date) {
      return new Date(date).toLocaleDateString();
    }
  }

  return (
    <DialogContent dividers={true}>
      <DialogContentText
        component={"div"}
        id="scroll-dialog-description"
        tabIndex={-1}
      >
        <Typography component={"div"} color={"rgba(0,0,0,0.87)"}>
          <img
            style={{ display: "block", margin: "0 auto 16px auto", maxHeight: "200px" }}
            src={logo}
            alt={"logo"}
          ></img>
          <p style={{ marginTop: 0 }}>
            This dashboard is a visualisation tool for the HS2 GI &amp;
            Water Management Database.
          </p>
          <p>
            Prior to use of this data visualisation tool please agree to the
            below general terms of service:
          </p>
          <p>
            <ol start="1" type="1">
              <li>
                All data supplied is delivered “as is”. The Information is
                licensed 'as is' and the Information Provider and/or Licensor
                excludes all representations, warranties, obligations and
                liabilities in relation to the Information to the maximum
                extent permitted by law.
              </li>
              <li>
                The Information Provider and/or Licensor are not liable for
                any errors or omissions in the information and shall not be
                liable for any loss, injury or damage of any kind caused by
                its use. The Information Provider does not guarantee the
                continued supply of the Information.
              </li>
            </ol>
          </p>
          <p>
            <div>
              <ol style={{ listStyle: "none" }}>
                <li>
                  API version No.: <strong>{appInfo.api_version}</strong>
                </li>
                <li>
                  Dashboard version No.: <strong>{config.appVersion}</strong>
                </li>
              </ol>
            </div>
          </p>
          <p style={{ marginBottom: "8px" }}>You can find the full terms of service below.</p>
        </Typography>
        {user?.allow_waterlevel &&
          <Accordion className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Water levels caveats</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetails}>
              <Typography component={"div"}>
                <p style={{ marginTop: 0 }}>
                  Prior to use of this data visualisation tool please agree to
                  the below terms of service.
                </p>
                <ol start="1" type="1">
                  <li>
                    <strong>General terms</strong>
                    <ol start="0" type="a">
                      <li style={{ listStyle: "none" }}>
                        All data is as provided by EKFB to GWP by
                        the {formatDate(appInfo.latest_gwl_upload_date)} ({appInfo.latest_gwl_load_number}) and re-produced ‘as-is’.
                      </li>
                      <li value="a">
                        Any information provided after this date, by EKFB to
                        GWP (or other sources) is not included in this
                        product.
                      </li>
                      <li>
                        Data sources are available on request as associated
                        metadata.
                      </li>
                      <li>
                        Data presented is given on a factual basis only.
                      </li>
                      <li>
                        All data is displayed as received (with exception of
                        formatting corrections).
                      </li>
                      <li>Data interpretation has not been undertaken.</li>
                      <li>
                        Appropriate quality assurance measures should be
                        undertaken prior to using data contain within this
                        product.
                      </li>
                      <li>
                        GWP Consultants LLP and Sior Consulting Ltd are not
                        liable for onward dispatch or use of this data.
                      </li>
                    </ol>
                  </li>
                  <li>
                    <strong>Pipe data</strong>
                    <ol start="0" type="a">
                      <li style={{ listStyle: "none" }}>
                        Pipe references are given in the “Borehole pipe”
                        dropdown, where available pipe reference is added to
                        the borehole ID with a “#” separator, e.g. pipe
                        reference =1 where Borehole pipe = ML047=CP001#1.
                      </li>
                      <li>
                        For AGS datasets, pipe details are presented only
                        where:
                        <ol style={{ listStyle: "none" }}>
                          <br />

                          <li>
                            An associated AGS field "MONG_ID" (monitoring
                            point reference ID) is available; or
                          </li>
                          <br />
                          <li>Where "PIPE_REF" is not "NULL".</li>
                          <br />
                        </ol>
                      </li>
                      <li>
                        For non-AGS datasets, the installation or pipe
                        reference is available only where an appropriate field
                        is available in the raw dataset as received from EKFB.
                      </li>
                      <li>
                        Where "PIPE_REF" is ambiguous due to multiple unique
                        values per dataset and distinct grouping of "LOCA_ID"
                        (location ID) and "MONG_ID", "PIPE_REF" is presented
                        as "TBC".
                      </li>
                    </ol>
                  </li>

                  <li>
                    <strong>Groundwater level data</strong>
                    <ol start="0" type="a">
                      <li style={{ listStyle: "none" }}>
                        AGS derived groundwater level data is displayed only
                        where:
                        <ul style={{ listStyle: "none" }}>
                          <br />

                          <li>
                            Data is received in the AGS "MOND" table; and
                          </li>
                          <br />
                          <li>
                            Where "MOND_TYPE" (Reading type) equals "WDEP".
                          </li>
                          <br />
                        </ul>
                      </li>
                      <li>
                        Data contained within "SAMP_WDEP" (Depth to the water
                        below ground surface at the time of sampling) has been
                        omitted due to a lack of supporting data to verify
                        validity.
                      </li>
                      <li>
                        Where no equivalent “MOND_TYPE” (Reading type) is
                        given in non-AGS datasets, “MOND_TYPE” has been
                        assumed based on “MOND_NAME” in accordance with ‘as
                        received’ datasets.
                      </li>
                      <li>
                        Where groundwater levels have been received with units
                        of "m", "mbgl", &amp; "GL to WL", it has been assumed
                        that the measurement relates to metres below ground
                        level (mbgl).
                      </li>
                      <li>
                        Location data, including groundwater, screen top,
                        screen base &amp; ground elevation data, can only be
                        assumed to be accurate to +/- 0.5m.
                      </li>
                      <li>
                        Location data, groundwater, screen top, screen base
                        &amp; ground elevation data is assumed to be correct
                        in the most recently issued associated dataset issued
                        by EKFB to GWP Consultants LLP (GWP), and is presented
                        on this basis.
                      </li>
                      <li>
                        Groundwater data is presented only where numeric
                        values for groundwater level or groundwater depth are
                        available.
                      </li>
                    </ol>
                  </li>
                </ol>
              </Typography>
            </AccordionDetails>
          </Accordion>}
        {user?.allow_chemistry && <Accordion sx={{ mt: 1 }} className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Water chemistry caveats</Typography>
          </AccordionSummary>
          <AccordionDetails className={styles.accordionDetails}>
            <Typography component={"div"}>
              <p>
                Prior to use of this data visualisation tool please agree to
                the below terms of service.
              </p>
              <ol start="1" type="1">
                <li>
                  <strong>General terms</strong>
                  <ol start="0" type="a">
                    <li style={{ listStyle: "none" }}>
                      All data is as provided by EKFB to GWP by
                      the {formatDate(appInfo.latest_chem_upload_date)} ({appInfo.latest_chem_load_number}) and re-produced ‘as-is’.
                    </li>
                    <li>
                      Any information provided after this date, by EKFB to
                      GWP (or other sources) is not included in this
                      product.
                    </li>
                    <li>
                      Data sources are available on request as associated
                      metadata.
                    </li>
                    <li>
                      Data presented is given on a factual basis only.
                    </li>
                    <li>
                      All data is displayed as received (e with exception of
                      formatting corrections ).
                    </li>
                    <li>Only numeric data has been processed.</li>
                    <li>Data interpretation has not been undertaken.</li>
                    <li>
                      Appropriate quality assurance measures should be
                      undertaken prior to using data contain within this
                      product.
                    </li>
                    <li>
                      GWP Consultants LLP and Sior Consulting Ltd are not
                      liable for onward dispatch or use of this data.
                    </li>
                  </ol>
                </li>
                <li>
                  <strong>Chemistry data</strong>
                  <ol start="0" type="a">
                    <li style={{ listStyle: "none" }}>
                      Chemistry data is presented from AGS tables “ERES”
                      (Environmental Contaminant Testing ) and “GCHM”
                      (Geotechnical Chemistry Testing) where, denoted as
                      "EW", "Water - EW", "Water - SW", "WATER" or "RIVER /
                      Running Surface Water" within the AGS field
                      "SAMP_TYPE" (sample type).
                    </li>
                    <li>
                      Chemistry data is presented from AGS table "MOND"
                      (Field-based measurements) where:
                      <ol style={{ listStyle: "none" }}>
                        <br />

                        <li>
                          AGS field "MOND_TYPE" is not equal to; "WDEP",
                          "WLEV", "DBLS", "BLEV", "PRES", "BAR", "FLOW",
                          "GPRS", "WHD" or "TEMP"; or
                        </li>
                        <br />
                        <li>
                          AGS field "MOND_NAME" (Client preferred name of
                          measurement) is not "NULL".
                        </li>
                        <br />
                      </ol>
                    </li>

                    <li>
                      Where no equivalent "MOND_TYPE" is given in non-AGS
                      datasets, "MOND_TYPE" has been assumed based on
                      "MOND_NAME" in accordance with ‘as received’ datasets.
                    </li>
                  </ol>
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>}
        <Feature name="rainfall">
          <Accordion sx={{ mt: 1 }} className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Acknowledgments</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.accordionDetails}>
              <Typography component={"div"}>
                <p>
                  The EKFB Hydrological Data Application uses Environment
                  Agency rainfall data from the real-time data API (Beta).
                  This is provided as open data under the{" "}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/"
                  >
                    Open Government Licence.
                  </a>
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Feature>
        <Accordion sx={{ mt: 1 }} className={styles.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Privacy Terms and conditions</Typography>
          </AccordionSummary>
          <AccordionDetails className={styles.accordionDetails}>
            <Typography component={"div"}>
                <strong>Your Rights Under UK GDPR:</strong>
                <ol start="1" type="1">
                  <li><strong>Right to Access:</strong> You can request access to the personal data we hold about you.</li>
                  <li><strong>Right to Rectification:</strong> If you believe the information, we hold is inaccurate or incomplete, you have the right to correct it.</li>
                  <li><strong>Right to Erasure:</strong> You can request the deletion of your personal data under certain circumstances.</li>
                  <li><strong>Right to Restriction of Processing:</strong> You have the right to limit how we use your data.</li>
                  <li><strong>Right to Data Portability:</strong> You can request a copy of your data in a commonly used format.</li>
                  <li><strong>Right to Object:</strong> You can object to the processing of your personal data for certain purposes.</li>
                </ol>
                <strong>Information We Collect</strong>
                <p>We collect and process the following information to ensure security, performance and reliability of the application:</p>
                <ol start="1" type="1">
                  <li>Name</li>
                  <li>Email</li>
                  <li>Login details</li>
                  <li>IP addresses for actions</li>
                  <li>Activity timestamps for login, selection, closure, logout, download, reset, signup, and agreement</li>
                </ol>
                <strong>Your Consent Matters:</strong>
                <p>By continuing to use our services, you agree to the terms outlined above and acknowledge that you have read and understood our data usage practices. For further information please see our Privacy Policy.</p>
                <p>If you have any questions or concerns, or wish to notify us of any requests related to the above rights, please contact us at <a href="mailto:ekfbhda@siorconsulting.com">ekfbhda@siorconsulting.com</a>.</p>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography component={"div"} color={"rgba(0,0,0,0.87)"}>
          {agreed !== true &&
            <p>
              Click <strong>AGREE</strong> to view the dashboard, by clicking
              here, you are agreeing to the terms of service.
            </p>
          }
          <p>Credits:</p>
          <ul style={{ listStyle: "none" }}>
            <li>
              <a href="https://gwp.uk.com/">GWP Consultants LLP</a>
            </li>
            <li>
              <a href="https://siorconsulting.com/">
                S&iacute;or Consulting Ltd
              </a>
            </li>
          </ul>
        </Typography>
      </DialogContentText>
    </DialogContent>
  )
}
