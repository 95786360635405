import './login-form.css';

import { Alert, AlertTitle, Button, CircularProgress, Container, Grid } from "@mui/material";
import { FormContainer, PasswordElement, TextFieldElement } from "react-hook-form-mui";
import { Link, useNavigate } from "react-router-dom";

import { spacing } from '@mui/system';
import { useAuth } from "../auth-provider/auth-provider";
import { useState } from "react";

export function LoginForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false)

  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (data) => {
    setIsLoading(true);
    setIsError(false);

    try {
      const redirectUrl = await login(data.email, data.password);
      if (redirectUrl) {
        navigate(redirectUrl);
      } else {
        setIsError(true);  // In case `login` function doesn't return a URL, show error
      }
    } catch {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const ForgotPasswordLink = () => <Link className="hda-forgot-password-link" to="/password-reset">Forgot password?</Link>;
  const RequestAccessLink = () => <a className="hda-request-access-link" target="_blank" href="https://forms.office.com/pages/responsepage.aspx?id=-f6MbwYBzUKVQ-IIurT88QsoHSpRHVhHmJLur1mR0gVUQUYyNDk0RFZWNTg2RDlaMVVUWlZXNzBGWS4u">Request access</a>;

  return (
    <Container>
      <FormContainer
        onSuccess={handleSubmit}
        FormProps={{
          id: "add-user-form"
        }}
      >
        <Grid justifyContent="left" container>
          <Grid item xs={12} md={4}>
            <Grid justifyContent="left" direction="column" container>
              {isError && <Grid>
                <Alert severity="error">
                  <AlertTitle>Login Failed</AlertTitle>
                  Check the email and password and try again.
                </Alert>
              </Grid>}
              <div className="mt-4">
                <Grid item>
                  <TextFieldElement
                    label={'Email address'}
                    name={'email'}
                    required
                    fullWidth
                    margin={'dense'}
                  />
                </Grid>
                <Grid item>
                  <PasswordElement
                    label={'Password'}
                    name={'password'}
                    required
                    fullWidth
                    margin={'dense'}
                  />
                </Grid>
              </div>
              <Grid item>
                <Grid flexDirection="row" justifyContent="space-between" container>
                <Grid display="flex" flexDirection="column" item>
                  <ForgotPasswordLink/>
                  <RequestAccessLink/>
                  </Grid>
                  <Grid display="flex" flexDirection="column" justifyContent="center" item>
                    <Button className="hda-btn-login-form-submit" type="submit" variant={"contained"} disabled={isLoading}>
                      Login {isLoading && <CircularProgress color="inherit" size={16} sx={{ ml: 1 }} />}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormContainer>
    </Container>
  );
}
