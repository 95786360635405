import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormGroup,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip
} from "@mui/material";
import { Suspense, useEffect, useMemo, useState } from "react";
import { ThreadForm, ThreadList } from "../components/comments/comments";
import {
  chemicalBoreholesState,
  chemistryDataState,
  chemistryDataTypeState,
  chemistryDatapointThreads,
  chemistryNullValuesState,
  determinantListState,
  selectedBoreholesChemistrySelector,
  selectedBoreholesChemistryState,
  selectedDataPointFamily,
  selectedDeterminantSelector,
} from "../state/recoil";
import {
  useRecoilRefresher_UNSTABLE,
  useRecoilState,
  useRecoilValue,
  useRecoilValueLoadable,
} from "recoil";

import { Alert } from "../components/alert/alert";
import { ErrorBoundary } from "../components/errorBoundary/errorBoundary";
import EsriNavigateButton from "../components/esri-navigate/esri-navigate";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { PointDetails } from "../components/pointDetails/pointDetails";
import SeriesGraph from "../components/SeriesGraph";
import api from "../lib/api";
import config from "../lib/config";
import fileDownload from "js-file-download";
import logUserActivity from "../common/user-activity-logging";
import { stringify } from "qs";
import { useAuth } from "../components/auth-provider/auth-provider";
import { useSearchParams } from "react-router-dom";

const Alerts = () => {
  const boreholes = useRecoilValueLoadable(chemicalBoreholesState).getValue() || [];
  const selectedDeterminant = useRecoilValue(selectedDeterminantSelector);
  const chemistryData = useRecoilValue(chemistryDataState);
  const selectedBoreholes = useRecoilValue(selectedBoreholesChemistryState);
  const determinants = useRecoilValueLoadable(determinantListState(selectedBoreholes.join(",")));
  const missingBoreholes = selectedBoreholes.filter(
    (borehole) => selectedDeterminant && selectedDeterminant.length > 0 && !chemistryData.find((dataset) => dataset.name === borehole)
  );
  const hasUngroupedData = chemistryData.some((dataset) =>
    dataset.data.some((data) => !data.is_grouped)
  );
  const [searchParams] = useSearchParams();
  const preselectedBoreholes = searchParams.get('boreholes')?.split(',') || [];
  const preselectedBoreholesWithoutData = preselectedBoreholes.filter(preselectedBorehole => !boreholes.find(borehole => preselectedBorehole === borehole));
  const preselectedDeterminants = searchParams.get('determinants')?.split(',') || [];
  const preselectedDeterminantsWithoutData = preselectedDeterminants.filter(preselectedDeterminant => !determinants.getValue()?.find(determinant => preselectedDeterminant === determinant));

  const handleAGOLClick = () => {
    logUserActivity({
      action: 'CLICKED',
      component: 'ALERT_AGOL_LINK_CHEMISTRY',
      data: config.ESRI_WEB_APP_WATER_LEVEL_LAYER_URL_CONCAT + preselectedBoreholesWithoutData.join(','),
    });
  }

  const hasAlerts =
    missingBoreholes?.length > 0 || hasUngroupedData || preselectedBoreholesWithoutData?.length > 0 || preselectedDeterminantsWithoutData && preselectedDeterminantsWithoutData?.length > 0;

  return (
    hasAlerts && (
      <Box marginBottom={3} marginTop={3}>
        <Card>
          <CardContent>
            {missingBoreholes?.length > 0 && <Alert>
              For the selected determinant, no information is available for the following monitoring locations. <strong>{missingBoreholes.join(", ")}</strong>.
            </Alert>}
            {hasUngroupedData && <Alert title="Warning - ungrouped series data points">
              Due to inconsistently reported determinant naming and/or units within incoming datasets, some data points in series (● solid circles) values were not converted to primary units.
            </Alert>}
            {preselectedBoreholesWithoutData?.length > 0 && <Alert title={`No information for available monitoring ${preselectedBoreholesWithoutData.length > 1 ? 'points' : 'point'}`}>
              No graphable water chemistry data is available for <strong>{preselectedBoreholesWithoutData.join(', ')}</strong>. <br />
              It may be that no monitoring data is available for this point, or that non-numeric readings exist that cannot be plotted, or data may be available under a different alias (please see the dropdown menu above).
            </Alert>}
            {preselectedDeterminantsWithoutData?.length > 0 && <Alert title={`No information for specified ${preselectedDeterminantsWithoutData.length > 1 ? 'determinants' : 'determinant'}`}>
              No determinant preselection can be completed for <strong>{preselectedDeterminantsWithoutData.join(', ')}</strong>. <br />
              It may be that no monitoring data is available for this determinant, or that non-numeric readings exist that cannot be plotted, or data may be available under a different determinant name (please see the dropdown menu above).
            </Alert>}
            {(preselectedBoreholesWithoutData?.length > 0 || preselectedDeterminantsWithoutData?.length > 0)  && <Alert severity="info" title="More information may be available">
              Please visit the following <strong>EKFB Groundwater Risk App (GWRA)</strong> link for detailed georeferenced excel based chemograph attachments: <br />
              <strong><a onClick={handleAGOLClick} target="_blank" href={`${config.ESRI_WEB_APP_WATER_CHEMISTRY_LAYER_URL_CONCAT}(${preselectedBoreholes.join(',')})`}>{config.ESRI_WEB_APP_WATER_CHEMISTRY_LAYER_NAME}</a></strong>.
            </Alert>}
          </CardContent>
        </Card>
      </Box>
    )
  );
};

const Graph = () => {
  const chemistryData = useRecoilValue(chemistryDataState);
  const type = useRecoilValue(chemistryDataTypeState);
  const selectedDeterminant = useRecoilValue(selectedDeterminantSelector);

  const [nullValues, setNullValues] = useRecoilState(chemistryNullValuesState);

  const pointsWithComments = useMemo(() => {
    let dataPoints = [];

    // TODO: duplicate code also used in waterlevels
    chemistryData.forEach((dataSet, seriesIndex) => {
      dataSet.data.forEach((point, pointIndex) => {
        if (point.comment_count) {
          dataPoints = [
            ...dataPoints,
            {
              series: dataSet.name,
              id: point.ags_data_id,
              x: point.x,
              commentCount: point.comment_count,
              openCommentCount: point.open_comment_count,
            },
          ];
        }
      });
    });
    return dataPoints;
  }, [chemistryData]);

  const pointsUngrouped = useMemo(() => {
    let dataPoints = [];

    // TODO: duplicate code also used in waterlevels
    chemistryData.forEach((dataSet) => {
      dataSet.data.forEach((point) => {
        if (!point.is_grouped) {
          dataPoints = [
            ...dataPoints,
            {
              series: dataSet.name,
              id: point.ags_data_id,
              x: point.x,
              unit: point.unit,
              commentCount: point.comment_count,
              openCommentCount: point.open_comment_count,
            },
          ];
        }
      });
    });
    return dataPoints;
  }, [chemistryData]);

  const DownloadButton = () => {
    const { user } = useAuth();
    const boreholes = useRecoilValue(selectedBoreholesChemistrySelector);
    const determinant = useRecoilValue(selectedDeterminantSelector);
    const [isDownloading, setIsDownloading] = useState(false);
    const handleButtonClicked = async () => {
      setIsDownloading(true);
      const response = await api.get('/chemistry/download/csv', {
        responseType: 'blob',
        params: {
          locationIDs: boreholes,
          determinant: determinant
        },
        paramsSerializer: params => stringify(params)
      })
      const filename = response.headers['content-disposition'].split('"')[1] + '.csv';
      fileDownload(response.data, filename);
      setIsDownloading(false);
    };

    return (
      user?.allow_downloads && <Tooltip title="Download data as CSV file">
        <IconButton disabled={isDownloading}>
          {isDownloading ?
            <CircularProgress color="inherit" size={18} />
            :
            <FileDownloadIcon onClick={handleButtonClicked} />}
        </IconButton>
      </Tooltip>);
  };

  return (
    <Box>
      {chemistryData && pointsWithComments && pointsUngrouped && (
        <SeriesGraph
          dataType={"chemistry"}
          data={chemistryData}
          isReversed={false}
          guides={[]}
          yTitle={
            config.chemistryDeterminantLabelMap[selectedDeterminant] ||
            chemistryData[0]?.legend?.unit
          }
          min={0}
          canSelectPoint={type === "all"}
          pointsWithComments={pointsWithComments}
          pointsWithAnnotations={pointsUngrouped}
          downloadButton={DownloadButton}
          omitNull={nullValues === "omit" ? true : false}
        ></SeriesGraph>
      )}
    </Box>
  );
};

const Filters = () => {
  const [dataType, setDataType] = useRecoilState(chemistryDataTypeState);
  const [nullValues, setNullValues] = useRecoilState(chemistryNullValuesState);

  const handleDateTypeChange = (event, value) => {
    setDataType(value.props.value);
  };

  const handleNullValuesChange = (event, value) => {
    if (value !== null) setNullValues(event.target.value);
  };

  return (
    <Card>
      <CardContent>
        <Box>
          <FormGroup row>
            <FormControl sx={{ m: 1 }}>
              <InputLabel id={"dataType"}>Graph type</InputLabel>

              <Select
                label={"Graph type"}
                id={"dataType"}
                value={dataType}
                onChange={handleDateTypeChange}
              >
                <MenuItem value={"dailyAverage"}>Daily Average</MenuItem>
                <MenuItem value={"all"}>All readings</MenuItem>
              </Select>
            </FormControl>
            <FormControl style={{ minWidth: 150 }} sx={{ m: 1 }}>
              <InputLabel id={"reversed"}>Null value behavior</InputLabel>
              <Select
                label={"Null value behavior"}
                id={"nullvalues"}
                value={nullValues}
                onChange={handleNullValuesChange}
              >
                <MenuItem value={"zero"}>Show as zero</MenuItem>
                <MenuItem value={"omit"}>Omit</MenuItem>
              </Select>
            </FormControl>
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
  );
};

const BoreholeSelect = ({ className }) => {
  const boreholes = useRecoilValueLoadable(chemicalBoreholesState);
  const [selectedBoreholes, setSelectedBoreholes] = useRecoilState(selectedBoreholesChemistrySelector);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const preselectedBoreholes = searchParams.get('boreholes')?.split(',');
    if (preselectedBoreholes) {
      const filteredPreselectedBoreholes = boreholes.getValue().filter(borehole => preselectedBoreholes?.includes(borehole));
      if (filteredPreselectedBoreholes?.length > 0) {
        setSelectedBoreholes(filteredPreselectedBoreholes);
      }
    }
  }, []);

  const handleBoreholeChange = async (event, value) => {
    if (value !== null) {
      setSelectedBoreholes(value);
    }
  };

  return (
    <Tooltip
      title={"List only contains boreholes which has chemistry data available"}
    >
      <Autocomplete
        className={className}
        multiple={true}
        getOptionLabel={(option) => (option ? option : "")}
        disablePortal
        id="combo-box-demo"
        value={selectedBoreholes}
        options={boreholes.getValue()}
        renderInput={(params) => <TextField {...params} label="Monitoring Point ID" />}
        onChange={handleBoreholeChange}
      />
    </Tooltip>
  );
};

const DeterminantSelect = () => {
  const selectedBoreholes = useRecoilValue(selectedBoreholesChemistryState);
  const determinants = useRecoilValueLoadable(determinantListState(selectedBoreholes.join(",")));
  const [selectedDeterminant, setSelectedDeterminant] = useRecoilState(selectedDeterminantSelector);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const preselectedDeterminants = searchParams.get('determinants')?.split(',');
    if (preselectedDeterminants) {
      const filteredPreselectedDeterminants = determinants.getValue().filter(determinant => preselectedDeterminants.map(d => d.toLowerCase())?.includes(determinant.toLowerCase()));
      if (filteredPreselectedDeterminants?.length > 0) {
        setSelectedDeterminant(filteredPreselectedDeterminants);
      }
    }
  }, [determinants]);

  const handleDeterminantChange = async (event, value) => {
    setSelectedDeterminant(value);
  };

  return (
    <Autocomplete
      disabled={!selectedBoreholes.length}
      getOptionLabel={(option) => (option ? option : "")}
      disablePortal
      id="combo-box-demo"
      value={selectedDeterminant}
      options={determinants.getValue()}
      renderInput={(params) => <TextField {...params} label="Determinant" />}
      onChange={handleDeterminantChange}
    />
  );
};

const CommentsBlock = ({ dataType }) => {
  const [selectedDataPoint, setSelectedDataPoint] = useRecoilState(
    selectedDataPointFamily(dataType)
  );
  const threads = useRecoilValue(chemistryDatapointThreads);
  const type = useRecoilValue(chemistryDataTypeState);
  const refreshData = useRecoilRefresher_UNSTABLE(chemistryDataState);

  return (
    <>
      {type === "all" && selectedDataPoint && (
        <>
          <Box marginBottom={3}>
            <ThreadForm
              selectedDataPoint={selectedDataPoint}
              afterSubmit={(thread) => {
                setSelectedDataPoint({
                  ...selectedDataPoint,
                  threads: selectedDataPoint.threads ? [...selectedDataPoint.threads, thread] : [thread],
                });
                refreshData();
              }}
            />
          </Box>
          <Box>
            <ThreadList threads={threads} dataType={dataType} />
          </Box>
        </>
      )}
    </>
  );
};

const Chemicals = () => {
  const boreholes = useRecoilValueLoadable(chemicalBoreholesState);
  const determinants = useRecoilValueLoadable(
    determinantListState(selectedBoreholesChemistryState)
  );
  const selectedBoreholes = useRecoilValue(selectedBoreholesChemistryState);
  const [selectedDeterminant, setSelectedDeterminant] = useRecoilState(
    selectedDeterminantSelector
  );

  return (
    <Box paddingBottom={3}>
      <Box marginBottom={3}>
        <ErrorBoundary>
          <Suspense fallback="Loading boreholes...">
            {boreholes.state !== "loading" && (
              <Card>
                <CardContent>
                  <div className="flex">
                    <BoreholeSelect className="w-full mr-4" />
                    <EsriNavigateButton boreholes={selectedBoreholes} url={config.ESRI_WEB_APP_WATER_CHEMISTRY_LAYER_URL_CONCAT} componentName="ESRI_NAVIGATE_BUTTON_CHEMISTRY" />
                  </div>
                </CardContent>
              </Card>
            )}
          </Suspense>
        </ErrorBoundary>
      </Box>
      <Box marginBottom={3}>
        <Card>
          <CardContent>
            <ErrorBoundary>
              <Suspense fallback="Loading determinants...">
                {determinants.state !== "loading" && (
                  <DeterminantSelect></DeterminantSelect>
                )}
              </Suspense>
            </ErrorBoundary>
          </CardContent>
        </Card>
      </Box>
      <Box marginBottom={3}>
        <ErrorBoundary>
          <Suspense>
            <Alerts></Alerts>
          </Suspense>
        </ErrorBoundary>
      </Box>
      {(selectedDeterminant && selectedBoreholes.length && (
        <>
          <Box>
            <Suspense fallback="Loading data...">
              <Filters></Filters>
              <Graph></Graph>
            </Suspense>
          </Box>
          <Box marginBottom={3}>
            <ErrorBoundary>
              <Suspense>
                <Box marginTop={3}>
                  <PointDetails dataType={"chemistry"} />
                </Box>
              </Suspense>
              <Suspense fallback={"Loading comments..."}>
                <CommentsBlock dataType={"chemistry"} />
              </Suspense>
            </ErrorBoundary>
          </Box>
        </>
      )) || <></>}
    </Box>
  );
};

export default Chemicals;
