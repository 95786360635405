import React from "react";
import "./index.css";
import App from "./app";
import featureFlags from "./feature-flag.json";
// import reportWebVitals from "./reportWebVitals";
import { FlagsProvider } from "flagged";
import { AuthProvider } from "./components/auth-provider/auth-provider";
import { RecoilRoot } from "recoil";
import { createRoot } from "react-dom/client";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/browser";
import config from "./lib/config";

const container = document.getElementById("root");
const root = createRoot(container);

Sentry.init({
  dsn: config.sentryDSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: Number(config.sentryTracesSampleRate),
  environment: config.reactAppEnvironment,
});

root.render(
  <React.StrictMode>
    <FlagsProvider features={featureFlags}>
      <RecoilRoot>
        <AuthProvider>
          <App />
        </AuthProvider>
      </RecoilRoot>
    </FlagsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
