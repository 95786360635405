import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useFeature } from 'flagged';
import { Feature } from 'flagged';

export function FAQ() {
  const isFeatureRainfall = useFeature('rainfall');

  return (
    <Stack spacing={2}>
      <Typography fontWeight={"bold"}>
        Q) How do I zoom out completely/reset the zoom?
      </Typography>
      <Typography>
        A) the Home button on the graph will reset your zoom to a fully zoomed
        out state.
      </Typography>

      <Typography fontWeight={"bold"}>
        Q) Why can’t I add comments to a datapoint on the graph?
      </Typography>
      {isFeatureRainfall ?
        <Typography>
          A) The graph must be set to ‘All Readings’ in the graph type dropdown –
          this is set by default when you open any graph. You can only record
          comments on datapoints for water levels – rainfall datapoints cannot
          have comments recorded against them.
        </Typography>
        :
        <Typography>
          A) The graph must be set to ‘All Readings’ in the graph type dropdown –
          this is set by default when you open any graph. You can only record
          comments on datapoints for water levels.
        </Typography>
      }

      <Typography fontWeight={"bold"}>
        Q) How do I add comments to a datapoint on the graph?
      </Typography>
      <Typography>
        A) Select any data point on the graph and a new section will appear
        below the graph to allow you to ‘Add Comment’. <br />
        First select the type of comment it is: Erroneous, General, Missing Data
        <br />
        Then add your comment below
        <br />
        Comments are threaded, so you can also reply to comments to add further
        detail.
        <br />
      </Typography>
      <Typography>
        A) Some graphs with a high density of data points will require zooming
        in to make sure a single data point is selected before a comment can be
        added.
      </Typography>
      <Typography fontWeight={"bold"}>
        Q) What do the numbers represent next to a data point on the graph?
      </Typography>
      <Typography>
        A) There are numbers next to data points that have comments e.g. 3(4)
        <br />
        3 represents the number of open comments
        <br />
        (4) represents the total number of comments
        <br />
      </Typography>

      <Feature name="rainfall">
        <Typography fontWeight={"bold"}>
          Q) Why can I not see rainfall data on the graph
        </Typography>
        <Typography>
          A) Rainfall will only show when there is only one borehole selected
        </Typography>
        <Typography>
          A) Rainfall cannot be shown for boreholes without location data
          (Eastings/Northings)
        </Typography>
      </Feature>

      <Typography fontWeight={"bold"}>
        Q) Why can I not see ground or pipe (screen top / screen bottom) levels
        on the graph
      </Typography>
      <Typography>
        A) ground and pipe levels will only show when there is only one borehole
        selected
      </Typography>
    </Stack>
  );
}
