import { AutocompleteElement, FormContainer, TextFieldElement } from 'react-hook-form-mui'
import { constSelector, useRecoilValue } from "recoil";
import { userRoles, userRolesOptions } from "../../../lib/user-roles";

import { TextField } from "@mui/material";
import { useUserRoles } from "../../../hooks/use-user-role";
import { userGroupsData } from "../../../state/recoil";

const InviteUserForm = ({ onSubmit }) => {
    const isPlatformAdmin = useUserRoles([userRoles.platform_admin]);
    const isAdmin = useUserRoles([userRoles.admin]);
    const roles = isPlatformAdmin ? userRolesOptions : userRolesOptions.slice(0, -1);
    const userGroups = useRecoilValue((isAdmin || isPlatformAdmin) ? userGroupsData: constSelector([]));

    return (
        <FormContainer
            onSuccess={onSubmit}
            FormProps={{
                id: "invite-user-form"
            }}
        >
            <TextFieldElement
                type={'email'}
                label={'Email'}
                name={'email'}
                required
                fullWidth
                margin={'dense'}
            />
            <AutocompleteElement
                name="role"
                label={'Role'}
                options={roles}
                required
                textFieldProps={{
                    margin: "dense"
                }}
            />

            <AutocompleteElement
            name="groups"
            label={'Groups'}
                sx={{ userSelect: "unset" }}
                multiple
                // getOptionLabel={(option) => option.name}
                getOptionLabel={option => option.name}
                disablePortal
                id="combo-box-demo"
                // value={selectedBoreholes}
                options={userGroups.map(group => ({id: group.id, label: group.name}))}
                renderInput={(params) => (
                    <TextField {...params} name="name" label="Name" />
                )}
                // onChange={handleBoreholeChange}
            />


        </FormContainer>
    );
};

export default InviteUserForm;
