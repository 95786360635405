import { FormContainer, TextFieldElement } from 'react-hook-form-mui';

const EmailForm = ({ defaultValues, onSubmit }) => {

  return (
    <FormContainer
      defaultValues={defaultValues}
      onSuccess={onSubmit}
      FormProps={{
        id: "email-form"
      }}
    >
      <TextFieldElement
        type={'email'}
        label={'Email'}
        name={'email'}
        required
        fullWidth
        margin={'dense'}
      />
    </FormContainer>
  );
};

export default EmailForm;
