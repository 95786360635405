import "react-confirm-alert/src/react-confirm-alert.css";

import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Suspense, useState } from "react";
import { commentsState, selectedDataPointFamily, waterLevelsData } from "../../state/recoil";
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue } from "recoil";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import api from "../../lib/api";
import { confirmAlert } from "react-confirm-alert";
import { useForm } from "react-hook-form";

export const Thread = ({ thread, dataType }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };
  return (
    <Box marginBottom={1}>
      <Card>
        <CardContent>
          <Box>
            <Box>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Stack spacing={2} direction="row" alignItems="center">
                  <Typography fontWeight="bold" sx={{ color: "neutral.darkBlue" }}>
                    {`${thread.user.first_name} ${thread.user.last_name}`}
                  </Typography>
                  <Typography sx={{ color: "#cacaba" }}>
                    {thread.created_at}
                  </Typography>
                  <Chip label={thread.status.toUpperCase()} />
                  <Chip label={thread.type.toUpperCase()} />
                  {thread.superseded?.is_superseded === 1 && <Chip label="SUPERSEDED" />}
                  {thread.superseded?.is_blacklisted === 1 && <Chip label="BLACKLISTED" />}
                </Stack>
                <IconButton onClick={toggleOpen}>
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Stack>
            </Box>
            <Box>
              <Stack>
                <Typography variant="h4" sx={{ p: 1 }}>
                  <b> {thread.title}</b>
                </Typography>
              </Stack>
            </Box>
          </Box>
          <Suspense fallback={"Loading comments..."}>
            {open && (
              <>
                <CommentList comments={thread.comments} />
                <CommentForm thread={thread} dataType={dataType} />
              </>
            )}
          </Suspense>
        </CardContent>
      </Card>
    </Box>
  );
};

export const Comment = ({ comment, showHeader }) => {
  return (
    <Box marginBottom={1}>
      <Box>
        {showHeader && (
          <Box>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack spacing={2} direction="row" alignItems="center">
                <Typography
                  fontWeight="bold"
                  sx={{ color: "neutral.darkBlue" }}
                >
                  {`${comment.user.first_name} ${comment.user.last_name}`}
                </Typography>
                <Typography sx={{ color: "#cacaba" }}>
                  {comment.created_at}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        )}
        <Box>
          <Typography sx={{ whiteSpace: "pre-line", p: 1 }} variant="body2">
            {comment.comment}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export const CommentList = ({ comments }) => {
  return (
    <>
      {comments.length
        ? [...comments]
          .sort((a, b) => a?.created_at?.localeCompare(b.created_at))
          .map((comment, index) => (
            <Box marginTop={2} key={comment.id}>
              <Comment showHeader={index !== 0} comment={comment}></Comment>
              {index !== comments.length - 1 && <Divider light />}
            </Box>
          ))
        : "No comments yet"}
    </>
  );
};

export const ThreadList = ({ threads, dataType }) => {
  return (
    <>
      <Typography gutterBottom variant="h5">
        Comments
      </Typography>
      {threads.length
        ? threads.map((thread) => (
          <Thread key={thread.id} thread={thread} dataType={dataType}></Thread>
        ))
        : "No threads yet"}
    </>
  );
};

export const CommentForm = ({ thread, dataType }) => {
  const [selectedDataPoint, setSelectedDataPoint] = useRecoilState(
    selectedDataPointFamily(dataType)
  );
  const refreshData = useRecoilRefresher_UNSTABLE(waterLevelsData);
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = (data) => {
    return new Promise((resolve, reject) => {
      const onConfirm = async (onClose) => {
        onClose();
        try {
          const comment = (await api.post("/comments", {
            thread_id: thread.id,
            comment: data.comment,
          })).data;
          const newSelectedDataPoint = {
            ...selectedDataPoint,
            threads: [...selectedDataPoint.threads.map(thread => ({...thread}))]
          };
          const newThread = newSelectedDataPoint.threads.find(_thread => _thread.id === thread.id);
          newThread.comments = [...newThread.comments, comment];
          setSelectedDataPoint(newSelectedDataPoint);
        } catch (e) {
          console.error(e);
          reject();
        }
        refreshData();
        reset();
        resolve();
      };

      const onCancel = (onClose) => {
        resolve();
        onClose();
      };

      // TODO: make this less spooky
      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to do this.",
        customUI: ({ onClose }) => {
          return (
            <Dialog
              open={true}
              onClose={() => {
                onCancel(onClose);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Confirm submission of comment(s).
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  component="div"
                  id="alert-dialog-description"
                >
                  By submitting this form you are agreeing to information
                  contained therein to be stored and presented to all users of
                  the EKFB Hydrological Data Application, accordingly you are
                  accepting liability associated with any interpretation of raw
                  as received and presented data. Additionally, by submitting
                  this form you are agreeing to the EKFB Hydrological Data
                  Application terms of service.
                  <br />
                  Comments will be used by the EKFB Hydrological Data
                  Application on the following basis:
                  <ul>
                    <li>Multiple comments per data point can be stored.</li>
                    <li>Comments cannot be amended after submission.</li>
                    <li>
                      All comments stored per data point will be available to
                      view by all EKFB Hydrological Data Application users.
                    </li>
                  </ul>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    onCancel(onClose);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    onConfirm(onClose);
                  }}
                  autoFocus
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          );
        },
      });
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box marginTop={3} marginBottom={3}>
        <FormControl fullWidth>
          <TextField
            label={"Reply"}
            aria-label="empty textarea"
            multiline
            fullWidth
            maxRows={4}
            required
            placeholder="Reply"
            {...register("comment")}
          />
        </FormControl>
      </Box>
      <Box>
        <Button
          sx={{ width: 87 }}
          type="submit"
          disabled={isSubmitting}
          variant="contained"
        >
          {isSubmitting ? (
            <CircularProgress color={"inherit"} size={24} />
          ) : (
            "Submit"
          )}
        </Button>
      </Box>
    </form>
  );
};

export const ThreadForm = ({ selectedDataPoint, afterSubmit }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  // TODO: make this not spooky
  const onSubmit = (data) => {
    return new Promise((resolve, reject) => {
      const onConfirm = async (onClose) => {
        onClose();
        try {
          const thread = await api.post("/threads", {
            asset_id: selectedDataPoint.id,
            type: data.type,
            comment: data.comment,
            title: data.title,
          });
          afterSubmit(thread.data);
        } catch (e) {
          console.error(e);
          reject();
        }
        reset();
        onClose();
        resolve();
      };

      const onCancel = (onClose) => {
        resolve();
        onClose();
      };

      // TODO: make this less spooky
      confirmAlert({
        title: "Confirm to submit",
        message: "Are you sure to do this.",
        customUI: ({ onClose }) => {
          return (
            <Dialog
              open={true}
              onClose={() => {
                onCancel(onClose);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                Confirm submission of comment(s).
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  component="div"
                  id="alert-dialog-description"
                >
                  By submitting this form you are agreeing to information
                  contained therein to be stored and presented to all users of
                  the EKFB Hydrological Data Application, accordingly you are
                  accepting liability associated with any interpretation of raw
                  as received and presented data. Additionally, by submitting
                  this form you are agreeing to the EKFB Hydrological Data
                  Application terms of service.
                  <br />
                  Comments will be used by the EKFB Hydrological Data
                  Application on the following basis:
                  <ul>
                    <li>Multiple comments per data point can be stored.</li>
                    <li>Comments cannot be amended after submission.</li>
                    <li>
                      All comments stored per data point will be available to
                      view by all EKFB Hydrological Data Application users.
                    </li>
                  </ul>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    onCancel(onClose);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    onConfirm(onClose);
                  }}
                  autoFocus
                >
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
          );
        },
      });
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardContent>
          <Typography gutterBottom variant="h5">
            Create comment
          </Typography>
          <input
            type={"hidden"}
            label={"Data point ID"}
            readOnly
            id="dataId"
            value={selectedDataPoint?.id}
            {...register("dataId")}
          />
          <Box marginBottom={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Type"
                required
                defaultValue={"general"}
                {...register("type")}
              >
                <MenuItem value={"general"}>General</MenuItem>
                <MenuItem value={"erroneous"}>Erroneous</MenuItem>
                <MenuItem value={"missing data"}>Missing Data</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box marginBottom={3}>
            <FormControl fullWidth>
              <TextField
                label={"Title"}
                aria-label="empty textarea"
                fullWidth
                maxRows={4}
                required
                placeholder="Title"
                {...register("title")}
              />
            </FormControl>
          </Box>
          <Box marginBottom={3}>
            <FormControl fullWidth>
              <TextField
                label={"Comment"}
                aria-label="empty textarea"
                multiline
                fullWidth
                maxRows={4}
                required
                placeholder="Comment"
                {...register("comment")}
              />
            </FormControl>
          </Box>
          <Box>
            <Button
              sx={{ width: 87 }}
              type="submit"
              disabled={isSubmitting}
              variant="contained"
            >
              {isSubmitting ? (
                <CircularProgress color={"inherit"} size={24} />
              ) : (
                "Submit"
              )}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </form>
  );
};
