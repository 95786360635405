import "dygraphs/dist/dygraph.css";
import { dateWindowState, valueRangeState } from "../../state/recoil";
import { useEffect, useRef } from "react";
import Dygraph from "dygraphs";
import config from "../../lib/config";
import { useRecoilState } from "recoil";

const guidesConfig = {
  ground: {
    name: 'Ground level',
    lineDash: [],
    color: config.colors.groundLevel
  },
  screenTop: {
    name: 'Screen top',
    lineDash: [5, 5],
    color: config.colors.pipe
  },
  screenBot: {
    name: 'Screen base',
    lineDash: [5, 5],
    color: config.colors.pipe
  },
};

export const DygraphComponent = ({
  dataType,
  series,
  pointClickCallback,
  yTitle,
  guides,
  selectedDataPoint,
  pointsWithDryReadings,
  pointsWithComments,
  pointsWithAnnotations = [],
  defaultDateWindow,
  defaultValueRange,
  visibleSeries,
}) => {
  const myRef = useRef();

  const [dateWindow, setDateWindow] = useRecoilState(dateWindowState(dataType));
  const [valueRange, setValueRange] = useRecoilState(valueRangeState(dataType));

  // Draw a canvas line
  const drawLine = (ctx, aX, aY, bX, bY, color) => {
    ctx.strokeStyle = color;
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(aX, aY);
    ctx.lineTo(bX, bY);
    ctx.stroke();
  };

  const customPlotter = (e) => {
    const ctx = e.drawingContext;
    const points = e.points;
    const color = e.color;
    const pointSize = 3;

    ctx.strokeStyle = color;
    const prevCoords = {};

    // Loop points to draw them and lines between them
    for (let i = 0; i < points.length; i++) {
      const point = points[i];
      const seriesName = e.setName;
      const seriesIndex = series.labels.findIndex(s => s === seriesName);
      const pointData = series.data[point.idx][seriesIndex];

      // Draw line
      if (pointData) {
        if (!prevCoords[seriesName]) {
          prevCoords[seriesName] = { prevX: null, prevY: null };
        }

        if (pointData?.isDry || isNaN(point.canvasx) || isNaN(point.canvasy)) {
          prevCoords[seriesName].prevX = null;
          prevCoords[seriesName].prevY = null;
        }
        else {
          if (prevCoords[seriesName].prevX !== null && prevCoords[seriesName].prevY !== null) {
            if (!isNaN(prevCoords[seriesName].prevX) && !isNaN(prevCoords[seriesName].prevY)) {
              drawLine(
                ctx,
                prevCoords[seriesName].prevX,
                prevCoords[seriesName].prevY,
                point.canvasx,
                point.canvasy
              );
            }
          }
          prevCoords[seriesName].prevX = point.canvasx;
          prevCoords[seriesName].prevY = point.canvasy;
        }
      }

      if (pointData?.isDry || isNaN(point.canvasx) || isNaN(point.canvasy)) {
        ctx.lineWidth = 2;
        if (!isNaN(point.canvasx) && !isNaN(point.canvasy)) {
          ctx.beginPath();
          ctx.moveTo(point.canvasx - pointSize, point.canvasy - pointSize);
          ctx.lineTo(point.canvasx + pointSize, point.canvasy + pointSize);
          ctx.moveTo(point.canvasx + pointSize, point.canvasy - pointSize);
          ctx.lineTo(point.canvasx - pointSize, point.canvasy + pointSize);
          ctx.stroke();
        }

        if (
          selectedDataPoint &&
          selectedDataPoint.id === pointData?.id &&
          !isNaN(point.canvasx) &&
          !isNaN(point.canvasy)
        ) {
          ctx.beginPath();
          ctx.arc(point.canvasx, point.canvasy, pointSize + 3, 0, 2 * Math.PI);
          ctx.lineWidth = 3;
          ctx.stroke();
        }
      } else {
        ctx.lineWidth = 1;
        if (!isNaN(point.canvasx) && !isNaN(point.canvasy)) {
          ctx.beginPath();
          ctx.arc(point.canvasx, point.canvasy, pointSize, 0, 2 * Math.PI);

          if (seriesName !== 'Rainfall' && !pointData?.is_grouped) {
            ctx.fillStyle = color;
            ctx.fill();
          } else {
            ctx.fillStyle = 'transparent';
            ctx.fill();
          }

          ctx.stroke();
        }

        if (
          selectedDataPoint &&
          pointData &&
          selectedDataPoint.id === pointData.id &&
          !isNaN(point.canvasx) &&
          !isNaN(point.canvasy)
        ) {
          ctx.beginPath();
          ctx.arc(point.canvasx, point.canvasy, pointSize + 3, 0, 2 * Math.PI);
          ctx.lineWidth = 3;
          ctx.stroke();
        }
      }
    }
  };

  useEffect(() => {
    if (myRef.current && series) {
      const graph = new Dygraph(myRef.current, series.seriesData, {
        plotter: customPlotter,
        colors: config.colors.series,
        visibility: visibleSeries.map((m) => m.visible),
        labels: series.labels,
        width: "100%",
        height: 600,
        connectSeparatedPoints: true,
        drawPoints: true,
        pointClickCallback: (event, point) => {
          const seriesIndex = series.labels.findIndex(l => l === point.name);
          const pointData = {
            id: series.data[point.idx][seriesIndex].id,
            series: point.name,
          };

          pointClickCallback(pointData);
        },
        strokeWidth: 1,
        pointSize: 3,
        labelsUTC: true,
        yRangePad: 50,
        xRangePad: 50,
        ylabel: yTitle,
        legend: "follow",
        legendFormatter: (data) => {
          let string = `<b>${data.xHTML}</b> `;
          data.series.forEach((s) => {
            if (s.y !== undefined) {
              if (s.yHTML) {
                string = `${string}<b style="color:${s.color}">${s.labelHTML}</b> : ${s.yHTML}<br/>`;
              } else {
                const seriesIndex = series.labels.findIndex(l => l === s.label);
                const pointData = series.data.find(d => d[0].getTime() === data.x)[seriesIndex];
                if (pointData?.isDry) {
                  string = `${string}<b style="color:${s.color}">${s.labelHTML}</b> : Dry<br/>`;
                }
              }
            }
          });
          return `<div style="width: 225px;border: 1px solid gray; border-radius: 4px; padding: 10px">${string}</div>`;
        },
        y2label: "Rainfall mm/day",
        xlabel: "Date",
        yLabelWidth: 25,
        xLabelHeight: 25,
        zoomCallback: (minDate, maxDate, yRange) => {
          setDateWindow([minDate, maxDate]);
          setValueRange([yRange[0][0], yRange[0][1]]);
        },
        drawHighlightPointCallback: (
          g,
          seriesName,
          ctx,
          cx,
          cy,
          color,
          pointSize,
          idx
        ) => {
          const point = series.data[idx][1];
          if (point?.isDry) {
            ctx.beginPath();
            ctx.moveTo(cx - pointSize, cy - pointSize);
            ctx.lineTo(cx + pointSize, cy + pointSize);
            ctx.moveTo(cx + pointSize, cy - pointSize);
            ctx.lineTo(cx - pointSize, cy + pointSize);
            ctx.lineWidth = 2;
            ctx.stroke();
          }
        },
        dateWindow: dateWindow || defaultDateWindow,
        valueRange: valueRange || defaultValueRange,
        interactionModel: {
          ...Dygraph.defaultInteractionModel,
          dblclick: () => {
            setDateWindow(defaultDateWindow);
            setValueRange(defaultValueRange);
          },
        },
        underlayCallback: function (ctx, area, g) {
          guides?.forEach((item, index) => {
            const coordY = g.toDomYCoord(item.value);
            // text
            ctx.fillStyle = guidesConfig[item.name].color;
            ctx.fillText(guidesConfig[item.name].name, area.x + 5 + index * 75, coordY - 5);
            // line
            ctx.beginPath();
            ctx.setLineDash(guidesConfig[item.name].lineDash);
            ctx.moveTo(0, coordY);
            ctx.lineTo(ctx.canvas.getBoundingClientRect().right, coordY);
            ctx.strokeStyle = guidesConfig[item.name].color;
            ctx.stroke();
            ctx.closePath();
            ctx.setLineDash([]);
          });
        },
        valueFormatter: (value, opts, seriesName, dygraph, row, col) => {
          if (col === 0) {
            return new Date(value).toLocaleString();
          } else {
            const isDry = series.data[row][col]?.isDry;
            return isDry ? "Dry" : value.toString();
          }
        },
        series: {
          Rainfall: {
            axis: "y2",
          },
        },
        axes: {
          y: {
            axisLabelWidth: 80,
          },
          y2: {
            independentTicks: true,
          },
        },
      });
      graph.ready(() => {
        graph.setAnnotations(
          [
            ...pointsWithAnnotations.map((point) => {
              return {
                series: point.series,
                x: +new Date(point.x),
                cssClass: "dygraphsAnnotation",
                shortText: `${point.unit || "n/a"}`,
                text: `${point.unit || "n/a"}`,
              };
            }),
            ...pointsWithComments.map((point) => {
              return {
                series: point.series,
                x: +new Date(point.x),
                cssClass: "dygraphsCommentAnnotation",
                shortText: `${point.openCommentCount || "✓"}(${point.commentCount})`,
                text: `Open comments: ${point.openCommentCount},  Total comments: ${point.commentCount}`,
              };
            }),
            ...pointsWithDryReadings.map((point) => {
              return {
                series: point.series,
                x: +new Date(point.x),
                cssClass: "dygraphsCommentAnnotation",
                shortText: 'DRY'
              };
            })
          ]
        );
      });
    }
  }, [
    guides,
    series.seriesData,
    series.labels,
    series,
    pointClickCallback,
    yTitle,
    selectedDataPoint,
    pointsWithComments,
    pointsWithAnnotations
  ]);

  return <div ref={myRef}></div>;
};
